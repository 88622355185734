import DataEditor, { IField } from "@components/DataEditor"
import Network from "@utils/Network"
import React, { ReactElement } from "react"
const styles = require("./index.module.css")

function ContactUsContainer(): ReactElement {
  const [loading, setLoading] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [contactFormData, setContactFormData] = React.useState({} as any)
  const [error, setError] = React.useState("" as any)
  const getContactUsSchema = (): IField[] => {
    return [
      {
        type: "DIVIDER",
        title: "Please register your query...",
      },
      {
        type: "INPUT",
        name: "name",
        title: "Full Name",
        validations: [
          {
            type: "REQUIRED",
          },
          {
            type: "MIN_LEN",
            value: 3,
          },
        ],
      },
      {
        type: "INPUT",
        name: "email",
        title: "Email ID",
        validations: [
          {
            type: "REQUIRED",
          },
          {
            type: "REGEX",
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          },
        ],
      },
      {
        type: "INPUT",
        name: "phone",
        title: "Phone No",
      },
      {
        type: "INPUT",
        name: "message",
        title: "Please type your query here...",
      },

      {
        type: "BUTTON",
        title: "Submit",
        flexBasis: "25%",
        onClick: onSubmit,
        disabled: loading,
      },
    ]
  }

  const onSubmit = async (err: any, data: any) => {
    setLoading(true)
    try {
      if (err && Object.keys(err).length) {
        setError("Please fill required fields!")
        return
      }
      setError("")
      const { data } = await Network.post("leads/register", {
        email: contactFormData.email,
        data: contactFormData,
      })
      if (data && data._id) {
        setSubmitted(true)
      }
    } catch (e) {
      alert("Server error occured, please try again later!")
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="relative" style={{ height: "300px", zIndex: -1 }}>
        <div className="absolute left-0 right-0 top-0 bottom-0">
          <iframe
            className="w-full h-full"
            src="https://www.openstreetmap.org/export/embed.html?bbox=14.496537498780528%2C35.887889632338016%2C14.508124641724864%2C35.89513853983704&amp;layer=mapnik"
          ></iframe>
        </div>
      </div>
      <div className="max-w-1366 mx-auto pt-16 px-8 md:px-0">
        <div
          className="w-full text-center bg-gray-200 mx-auto p-4 rounded-lg"
          style={{ marginTop: "-150px", maxWidth: "300px" }}
        >
          <div>
            <h3 className="text-2xl font-bold">Contact Us</h3>
          </div>
          <div>
            <h5 className="py-4 ">We are here to help!</h5>
          </div>
        </div>
      </div>
      <div className="max-w-1366 mx-auto  px-8 md:px-0 pb-16">
        <div className="w-full md:w-2/3 mx-auto">
          {error && error.length ? (
            <div className="rounded-lg p-4 bg-red-400 text-white">{error}</div>
          ) : (
            ""
          )}
          {submitted ? (
            <div className="rounded-lg bg-gray-300">
              <h3 className="text-xl p-4 shadow-lg">
                Thankyou for contacting us! One of our agents with get in touch
                with you as soon as possible!
              </h3>
            </div>
          ) : (
            <DataEditor
              validation={true}
              onChange={(data: any) => setContactFormData(data)}
              schema={getContactUsSchema() as any}
              data={contactFormData}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactUsContainer
