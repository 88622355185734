import ContactUsContainer from "@containers/ContactUs"
import DefaultLayout from "@layouts/default"
import React, { ReactElement } from "react"

function AboutUs(): ReactElement {
  return (
    <>
      <DefaultLayout pageTitle="Contact Us">
        <ContactUsContainer />
      </DefaultLayout>
    </>
  )
}

export default AboutUs
